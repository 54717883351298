
//import { Modal } from "bootstrap/dist/js/bootstrap.min";

export function debounce(func, timeout = 300){
    var timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

export function getReferrer() {
    let url = new URL(window.location.href);
    return url.searchParams.get("ref") || document.referrer || "";
}

export function getRegistrantKey(eventId = "") {
    let url = new URL(window.location.href);
    return url.searchParams.get("rk") || localStorage.getItem("rk_" + eventId) || localStorage.getItem("rk") || "";
}

export function getAllRegistrantKeys() {
    var retVal = [];
    for (var i = 0; i < localStorage.length; i++) {
        var key = localStorage.key(i);
        if (key.startsWith("rk")) {
            retVal.push(localStorage.getItem(key));
        }
    }
    return retVal;
}

export function getPoweredByElement() {
    var imgEl = document.createElement("img");
    imgEl.className = "h-100";
    imgEl.ariaLabel = "Powered by eventbuilder";
    imgEl.src = "/assets/images/poweredby.png";

    var anchorEl = document.createElement("a");
    anchorEl.className = "btn btn-link d-inline-flex align-items-center border border-0 h-100";
    anchorEl.target = "_blank";
    anchorEl.ariaLabel = "Powered by eventbuilder";
    anchorEl.href = "https://www.eventbuilder.rocks";

    anchorEl.appendChild(imgEl);

    return anchorEl;
}

export async function getDynamicModal(opts) {
    return new Promise((resolve, reject) => {
        var modalName = opts.name || `modal${Date.now()}${Math.ceil(Math.random * 100)}`;

        var modalElement = document.createElement("div");
        modalElement.id = modalName;
        modalElement.setAttribute("class", "modal");
        modalElement.setAttribute("data-bs-backdrop", opts.backdrop || "static");
        modalElement.setAttribute("data-bs-keyboard", opts.keyboard || "false");
        modalElement.setAttribute("aria-labelledby", `${modalName}Label`);
        modalElement.setAttribute("tabindex", "-1");
        var modalDialogElement = document.createElement("div");
        modalDialogElement.setAttribute("class", "modal-dialog modal-fullscreen-md-down modal-lg");
        var modalContentElement = document.createElement("div");
        modalContentElement.setAttribute("class", "modal-content");
        var modalHeaderElement = document.createElement("div");
        modalHeaderElement.setAttribute("class", "modal-header text-bg-primary");
        var modalHeaderH5Element = document.createElement("h5");
        modalHeaderH5Element.id = `${modalName}Label`;
        modalHeaderH5Element.setAttribute("class", "modal-title");
        modalHeaderH5Element.innerHTML = opts.title || "Information";
        modalHeaderElement.appendChild(modalHeaderH5Element);
        var modalHeaderButtonElement = document.createElement("button");
        modalHeaderButtonElement.setAttribute("class", "btn-close");
        modalHeaderButtonElement.setAttribute("data-bs-dismiss", "modal");
        modalHeaderButtonElement.setAttribute("aria-label", "Close");
        modalHeaderButtonElement.addEventListener("click", () => resolve(false));
        modalHeaderElement.appendChild(modalHeaderButtonElement);
        modalContentElement.appendChild(modalHeaderElement);
        var modalBodyElement = document.createElement("div");
        modalBodyElement.setAttribute("class", "modal-body");
        modalBodyElement.innerHTML = opts.body || "";
        modalContentElement.appendChild(modalBodyElement);
        var modalFooterElement = document.createElement("div");
        modalFooterElement.setAttribute("class", "modal-footer");
        if (opts.okButtonText != "") {
            var modalOkButtonElement = document.createElement("button");
            modalOkButtonElement.setAttribute("class", "btn btn-primary ps-4 pe-4");
            modalOkButtonElement.setAttribute("data-bs-dismiss", "modal");
            modalOkButtonElement.innerHTML = opts.okButtonText || "Ok";
            modalOkButtonElement.addEventListener("click", () => resolve(true));
            modalFooterElement.appendChild(modalOkButtonElement);
        }
        if (opts.cancelButtonText != "") {
            var modalCancelButtonElement = document.createElement("button");
            modalCancelButtonElement.setAttribute("class", "btn btn-outline-dark ps-4 pe-4");
            modalCancelButtonElement.setAttribute("data-bs-dismiss", "modal");
            modalCancelButtonElement.innerHTML = opts.cancelButtonText || "Close";
            modalCancelButtonElement.addEventListener("click", () => resolve(false));
            modalFooterElement.appendChild(modalCancelButtonElement);
        }
        modalContentElement.appendChild(modalFooterElement);
        modalDialogElement.appendChild(modalContentElement);
        modalElement.appendChild(modalDialogElement);
        modalElement.addEventListener("hidden.bs.modal", (e) => {
            Modal.getInstance(e.target).dispose();
            e.target.remove();
        })
        alert("fix modal!");
        //new Modal(modalElement).show();
    });
}

export function capitalizeFirstLetter(string) {
    return string[0].toUpperCase() + string.slice(1);
}

export function downloadBlob(blob, filename) {
    var element;
    try {
        element = document.createElement("a");
        element.href = window.URL.createObjectURL(blob);
        element.download = filename;
        document.body.appendChild(element);
        element.click();
    } catch (ex) {
        console.error(ex);
    } finally {
        try { document.body.removeChild(element); } catch (ex) { console.error(ex); }
    }
}
